import { ReactNode } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './PageTemplate.scss';
import { HeaderType } from '../../components/Header/Header';

type Props = {
  children: ReactNode;
  headerType: HeaderType;
};
export default function PageTemplate({ children, headerType }: Props) {
  return (
    <>
      <div className="wrapper">
        <Header headerType={headerType} />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
}
