export type HeaderType = 'normal' | 'uuid' | 'unixToDate';
interface Props {
  headerType: HeaderType;
}
export default function Header({ headerType }: Props) {
  const headers: { [key in HeaderType]: JSX.Element } = {
    normal: (
      <>
        <header>
          <h1>ブラウザツール</h1>
          <p>ブラウザ上で使える無料ツールを作成しています。</p>
        </header>
      </>
    ),
    uuid: (
      <>
        <header>
          <h1>UUID生成</h1>
          <p>UUIDを生成します。（同時に100個まで）</p>
        </header>
      </>
    ),
    unixToDate: (
      <>
        <header>
          <h1>UNIX時刻→日付変換</h1>
          <p>UNIX時刻を日付形式に変換します。（入力後即反映されます。）</p>
          <p>ミリ秒にチェックを入れるとミリ秒対応になります。</p>
        </header>
      </>
    ),
  };
  return headers[headerType];
}
