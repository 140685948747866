import {
  Button,
  Checkbox,
  FormGroup,
  MenuItem,
  NumericInput,
} from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import PageTemplate from '../../../templates/PageTemplate/PageTemplate';
import './GenerateUUID.scss';
import useGenerateUUID from './hooks/useGenerateUUID';

export interface Film {
  name: string;
  rank: number;
}
type Enclosing = 'なし' | 'ダブルクォーテーション';
export interface Options {
  enclosing: Enclosing;
  delimiter: '' | 'カンマ';
}

const TOP_100_FILMS: Film[] = [
  { name: 'なし' },
  { name: 'ダブルクォーテーション' },
].map((f, index) => ({ ...f, rank: index + 1 }));

const renderFilm: ItemRenderer<Film> = (
  film,
  { handleClick, handleFocus, modifiers, query },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={film.rank}
      label={film.name}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={`${film.rank}.`}
    />
  );
};
export default function GenerateUUID() {
  const [count, setCount] = useState(1);
  const [options, setOptions] = useState<Options>({
    delimiter: '',
    enclosing: 'なし',
  });
  const { generateV4, uuids } = useGenerateUUID(count, options);
  const [selectedFilm, setSelectedFilm] = useState<Film | undefined>(
    TOP_100_FILMS[0],
  );
  const handleCountChange = (
    valueAsNumber: number,
    valueAsString: string,
    inputElement: HTMLInputElement | null,
  ) => {
    if (100 < valueAsNumber) {
      toast.error('上限は100個です。');
      setCount(100);
    } else {
      setCount(valueAsNumber);
    }
  };
  const handleCheckComma: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setOptions((old) => {
      return { ...old, delimiter: e.target.checked ? 'カンマ' : '' };
    });
  };
  const handleSelectEnclosing = (
    item: Film,
    event?: React.SyntheticEvent<HTMLElement, Event> | undefined,
  ) => {
    setSelectedFilm(item);
    console.log(item);
    setOptions((old) => {
      return { ...old, enclosing: item.name as Enclosing };
    });
  };
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    console.log(e, options);
    generateV4();
  };
  const handleCopy = useCallback(() => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(uuids.join('')).then(
      () => {
        toast.success('コピーしました');
      },
      () => {
        toast.error('コピーに失敗しました');
      },
    );
  }, [uuids]);

  return (
    <>
      <PageTemplate headerType="uuid">
        <form onSubmit={handleSubmit}>
          <FormGroup label="生成数">
            <NumericInput
              min={1}
              max={100}
              value={count}
              onValueChange={handleCountChange}
            />
          </FormGroup>

          <p>オプション</p>
          <div className="option">
            <FormGroup className="form-label" label="囲み文字">
              <Select<Film>
                items={TOP_100_FILMS}
                fill={true}
                resetOnQuery={true}
                itemRenderer={renderFilm}
                filterable={false}
                noResults={
                  <MenuItem
                    disabled={true}
                    text="No results."
                    roleStructure="listoption"
                  />
                }
                onItemSelect={handleSelectEnclosing}
              >
                <Button
                  text={selectedFilm?.name}
                  rightIcon="double-caret-vertical"
                  placeholder="Select a film"
                />
              </Select>
            </FormGroup>
            <FormGroup className="form-label" label="区切り文字">
              <Checkbox onChange={handleCheckComma}>カンマ</Checkbox>
            </FormGroup>
          </div>
          <Button type="submit">生成</Button>
        </form>
        <section className="result">
          <div className="uuid">
            {uuids.map((uuid, i) => {
              return <div key={i}>{uuid}</div>;
            })}
          </div>
          <Button className="copy-button" type="button" onClick={handleCopy}>
            コピー
          </Button>
        </section>
      </PageTemplate>
    </>
  );
}
