import { Card, Elevation } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
export type CardLinkProps = {
  title: string;
  description: string;
  handleClick: () => void;
};
export default function CardLink({
  title,
  description,
  handleClick,
}: CardLinkProps) {
  return (
    <Card interactive={true} elevation={Elevation.TWO} onClick={handleClick}>
      <h2>{title}</h2>
      <p>{description}</p>
    </Card>
  );
}
