import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const pages = {
  root: '/',
  uuidGenerate: 'uuid',
  unixToDate: 'unix-to-date',
} as const;
export type Pages = (typeof pages)[keyof typeof pages];

export default function useLink() {
  const navigate = useNavigate();
  const navi = useCallback(
    (page: Pages) => {
      navigate(page);
    },
    [navigate],
  );
  return { navi };
}
