import PageTemplate from '../../templates/PageTemplate/PageTemplate';
import { CardLinkProps } from '../Card/Card';
import CardLink from '../Card/Card';
import useLink from '../../hooks/useLink/useLink';
import './TopScreen.scss';

export default function TopScreen() {
  const { navi } = useLink();
  const cardLinks: CardLinkProps[] = [
    {
      title: 'UUID生成',
      description: 'UUID（V4）の生成とクリップボードコピー',
      handleClick: () => navi('uuid'),
    },
    {
      title: 'UNIX時刻→日付変換',
      description: 'UNIXタイムスタンプを日付形式に変換',
      handleClick: () => navi('unix-to-date'),
    },
  ];
  return (
    <>
      <PageTemplate headerType="normal">
        <div className="tools">
          {cardLinks.map((e) => {
            return (
              <CardLink
                title={e.title}
                description={e.description}
                handleClick={e.handleClick}
              />
            );
          })}
        </div>
      </PageTemplate>
    </>
  );
}
