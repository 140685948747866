import { Checkbox, FormGroup, NumericInput } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import PageTemplate from '../../../templates/PageTemplate/PageTemplate';
import './UnixTimeToDate.scss';
import { toast } from 'react-toastify';

export default function UnixTimeToDate() {
  const [unixTime, setUnixTime] = useState(new Date().getTime());
  const [offset, setOffset] = useState(9);
  const [isEnabledMilli, setIsEnabledMilli] = useState(true);
  const [date, setDate] = useState('');
  useEffect(() => {
    if (offset.toString().slice(-1) === '.') {
      return;
    }
    if (offset < -12 || 11 < offset) {
      return;
    }

    const date = new Date(unixTime * (isEnabledMilli ? 1 : 1000));

    const diff = -1 * offset * 60 * 60 * 1000;
    const local = new Date(date.getTime() - diff);

    const dateStr =
      local.toISOString().slice(0, 19) +
      (isEnabledMilli ? local.toISOString().slice(19, 23) : '') +
      `+${('00' + offset).slice(-2)}:${(
        '00' +
        (offset - Math.floor(offset)) * 60
      ).slice(-2)}`;

    setDate(dateStr);
  }, [offset, isEnabledMilli, unixTime]);
  const handleCountChange = (
    valueAsNumber: number,
    valueAsString: string,
    inputElement: HTMLInputElement | null,
  ) => {
    if (isNaN(valueAsNumber)) {
      toast.error('数値を入力してください。');
      return;
    }
    setUnixTime(valueAsNumber);
  };
  const handleOffsetChange = (
    valueAsNumber: number,
    valueAsString: string,
    inputElement: HTMLInputElement | null,
  ) => {
    if (isNaN(valueAsNumber)) {
      toast.error('数値を入力してください。');
      return;
    }
    setOffset(valueAsNumber);
  };

  const handleCheckComma: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsEnabledMilli(e.target.checked);
    const unix = e.target.checked
      ? unixTime * 1000
      : Math.floor(unixTime / 1000);
    setUnixTime(unix);
  };
  return (
    <>
      <PageTemplate headerType="unixToDate">
        <FormGroup label="UNIX時刻を入力してください。">
          <NumericInput
            min={0}
            // max={isEnabledMilli ? 253402300799999 : 253402300799} // 9999-12-31T23:59:59.999Z
            buttonPosition="none"
            value={unixTime}
            onValueChange={handleCountChange}
          />
          <Checkbox onChange={handleCheckComma} checked={isEnabledMilli}>
            ミリ秒
          </Checkbox>
        </FormGroup>
        <FormGroup label="UTCオフセット">
          <NumericInput
            min={-12}
            max={11}
            value={offset}
            onValueChange={handleOffsetChange}
          />
        </FormGroup>
        <h2>日付変換結果</h2>
        <p>{date}</p>
      </PageTemplate>
    </>
  );
}
