import { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { Options } from '../GenerateUUID';

export default function useGenerateUUID(count: number, options: Options) {
  const [uuids, setUUIDs] = useState([] as string[]);

  const generateV4 = useCallback(() => {
    const uuids = [];
    for (let i = 0; i < count; i++) {
      let uuidV4 = v4();
      console.log(uuidV4);
      console.log(options);
      if (options.enclosing === 'ダブルクォーテーション') {
        uuidV4 = `"${uuidV4}"`;
      }
      if (options.delimiter === 'カンマ' && i < count - 1) {
        uuidV4 += ',';
      }
      if (i < count - 1) {
        uuidV4 += '\n';
      }
      uuids.push(uuidV4);
    }
    console.log(uuids);
    setUUIDs(uuids);
  }, [count, options]);
  return { generateV4, uuids };
}
