import { AnchorButton } from '@blueprintjs/core';
import './Footer.scss';
export default function Footer() {
  return (
    <>
      <footer>
        <div className="footer-wrapper">
          <div className="all-tools">
            <a href="/">すべてのツール</a>

            <ul>
              <li>
                <a href="/uuid">UUID生成</a>
              </li>

              <li>
                <a href="/unix-to-date">UNIX時刻変換</a>
              </li>
            </ul>
          </div>
          <div className="links">
            <p>関連</p>
            <AnchorButton href="https://710ooooo.com">
              ブログサイト「たるだめ」
            </AnchorButton>
          </div>
        </div>
        <div className="bottom">© 710ooooo.com</div>
      </footer>
    </>
  );
}
