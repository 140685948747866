import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import TopScreen from './components/TopScreen/TopScreen';
import GenerateUUID from './components/Other/GenerateUUID/GenerateUUID';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UnixTimeToDate from './components/Date/UnixTimeToDate/UnixTimeToDate';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBcNnHEe5FaMQrxFhfBaX9YNjLs-AEn70s',

  authDomain: 'nato-dev-web-tools.firebaseapp.com',

  projectId: 'nato-dev-web-tools',

  storageBucket: 'nato-dev-web-tools.appspot.com',

  messagingSenderId: '496212165547',

  appId: '1:496212165547:web:1d82bced57f459c31488ee',

  measurementId: 'G-VB2FN92V5N',
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const router = createBrowserRouter([
  {
    path: '/',
    element: <TopScreen />,
  },
  {
    path: '/uuid',
    element: <GenerateUUID />,
  },
  {
    path: '/unix-to-date',
    element: <UnixTimeToDate />,
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
